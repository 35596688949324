<template>
<v-dialog width="200"
:disabled="disabled"
  >

<template v-slot:activator="{ props }">
  <v-label v-if= "item[header.value] !== '' && item[header.value] !== undefined && header.title!== null" 
  :class="isMarked ? 'v-label-dark-marked' : 'v-label-dark'"
  v-bind="props" 
  bg-color="red"
  >{{ lookup(item,header) }}</v-label>
  <v-label v-else-if="header.title !== null"
  :class="isMarked ? 'v-label-grey-marked' : 'v-label-grey'"
    v-bind="props" 
  >{{ header.value }}</v-label>
</template>
<template v-slot:default="{ isActive }">
  <v-card :title="header.value">
    <v-card-text>
      <v-checkbox v-if="header.type === 'boolean'"
        v-model="item[header.value]"
        :label="header.value"
        single-line
        counter
        @update:modelValue="onValueChange"
        :rules="item[header.required]"
        :disabled="disabled || header.disabled"
      ></v-checkbox>
      <v-select v-else-if="header.type === 'select'"
          :items="refTables[header.optionstable]"
          item-text="title"
          item-value="code"
          :v-model="item[header.value]" 
          @update:modelValue="onValueChange"
          :label = "header.text" 
          :disabled="disabled || header.disabled"
          ></v-select>
    <v-text-field v-else-if= "header.type === 'barcode'"
        v-model="item[header.value]"
        :label="header.value"
        @update:modelValue="onValueChange"
        single-line
        counter
        prepend-icon="mdi-barcode"
        @click:prepend="scan = true"
      > 
      <v-dialog content-class='my-custom-dialog' width="600" height="100"
        v-model="scan" 
          >
      <ScanBarcode v-on="handler" :field="header.value"> </ScanBarcode>
      </v-dialog></v-text-field>
      <v-text-field v-else-if="header.title !== null && header.type === 'number'"
      v-model.number="item[header.value]"
        :label="header.value"
        type="number"
        @update:modelValue="onValueChange"
        single-line
        counter>
      </v-text-field>
      <v-text-field  v-else-if="header.title !== null && header.type !== undefined"
        v-model="item[header.value]"
        :label="header.value"
        @update:modelValue="onValueChange"
        single-line
        counter
      ></v-text-field> 
    </v-card-text>
  <v-card-actions>
  <v-spacer></v-spacer>
  <v-btn text="Close Dialog" @click="isActive.value=false"></v-btn>
</v-card-actions>
</v-card>
</template>
</v-dialog>
    
</template>
<script>
import support from '../plugins/support'
import ScanBarcode from '@/components/ScanBarcode.vue'


export default {
    components: { ScanBarcode
    },
props: {
    header: Object,
    value: Object,
    refTables: Object,
    disabled: Boolean,
    position:Number,
    isMarked:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      scan: false,
      item: this.value,
      required: [v => !!v || 'Required'],
      handler: {
        array_update: this.setArrayValue,
        closeScanner: this.toggleScan,
        barcodeInput: this.handleBarcode
      }

    }
  },
  mounted () {
  },
  created () {
    // this.$watch(
    //   'value', () => { this.item = this.value })
  },
  methods: {
    
    handleBarcode(info){
      this.item[info.field] = info.value
      this.$emit('itemUpdate', {record:this.item, position:this.position})
      
    },
    toggleScan() {
      this.scan = !this.scan
    },
    selectValue (value) {
      if (value !== undefined) {
        return value.toString()
       }
      return ''
    },
   
    lookup (item, header) {
      if (item !== undefined) {
        return support.lookup(item, header)
      }
      return item
    },
    onValueChange(v) {
      this.item[this.header.value] = v
      this.$emit('itemUpdate', {record:this.item, position:this.position})

    },
    barcodeClicked(v){
      console.log(v)
    }
   
  }

}
</script>
 <style>
    /* p {
      color: red;
    } */
    p.required{
      color:red
    }
    p.normal{

    }

.v-label-white {
  color: rgb(248, 245, 245);
  font-weight: lighter;
}
.v-label-white-marked {
  color: rgb(248, 245, 245);
  font-weight: lighter;
  background:red;
}
.v-label-grey{
  color: rgb(97, 95, 95);
  font-weight: lighter;
}
.v-label-grey-marked{
  color: rgb(97, 95, 95);
  font-weight: lighter;
  background:red;
}
.v-label-dark {
  color: rgb(34, 33, 33);
  font-weight: bolder;
}
.v-label-dark-marked {
  color: rgb(34, 33, 33);
  font-weight: bolder;
  background:red;
}

.my-custom-dialog  {
    position: absolute;
    top: 0;
    right: 0;
}
 /* .my-custom-dialog {
    align-self: flex-end;
  } */
</style>
