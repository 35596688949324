<template>
  <v-container fluid class="fill-width">
    <v-row>
   <v-col cols="9">
     <v-data-table
    ref="mainTable"
    :items="tableItems"
    :headers="headers"
    v-model:page="lastPage"
    :items-per-page="itemsPerPage"
    :show-current-page="true"
    @click:row="handleClick"
    >
    <template v-slot:item="{ item, idx }">
        <tr >
          <th
          v-for="(header,index) in headers"
            :key="index">
            <v-btn v-if="header.type==='delete'"
              v-model="item.delete"
              :disabled="header.disabled"
              @click="deleteRow()">Delete</v-btn>
          <RecordEditMenu v-else-if="header.type==='selected'" :value="item" :header="header" :refTables="refTables" v-on="handler" :btns="btns"
              :disabled="disabled" ></RecordEditMenu>
           <v3TableEditCell v-else-if="header.type !== null"
           :header="header"
           :value="item"
           :refTables="refTables"
           :position="idx"
           :isMarked="inMarkObject(item)"
           @itemUpdate="updateItem"></v3TableEditCell>
          </th>
        </tr>

      </template>
    </v-data-table>
  </v-col >
  <v-col cols="3" align-self="end">

    <div v-for="(btn, i) in refTables['hot_buttons']" :key='i'>
        <v-btn 
        class="mt-2"
        size="small"
        dark 
        color="blue"
        @click="addHotRecord(btn.species, btn.age)"
        >
        {{ btn.code }}
        </v-btn>
</div>
  </v-col>
</v-row>
    <v-tooltip bottom>
    <template v-slot:activator="{ attrs }">
    <v-fab-transition>
              <v-btn
                color="pink"
                dark
                absolute
                bottom
                right
                fab
                v-bind="attrs"
          @click="addRow">
              
                <v-icon>mdi-plus</v-icon>
       </v-btn>
       </v-fab-transition>
       </template>
       </v-tooltip>
  </v-container>
</template>

<script>
//import TableEditCell from '@/components/v3TableEditCell.vue'
import RecordEditMenu from '@/components/RecordEditDialog.vue'
import v3TableEditCell from '@/components/v3TableEditCell.vue'

//import IntegerArrayAsStringCell from '@/components/IntegerArrayAsStringCell.vue'
import support from '../plugins/support'
export default {
  name: 'TableData',
  components: { 
    //TableEditCell, 
    RecordEditMenu,
    v3TableEditCell
  },
  selectedCell: null,

  props: {
    value: Array,
    fields: Array,
    parent_id: String,
    refTables: Object,
    tableName: String,
    keyField: String,
    showAll: Boolean,
    disabled: Boolean,
    table_global_id:{
      type: String,
      default: null
    },
    formatter:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      page:1,
      itemsPerPage:10,
      tableItems: this.value.map((item, index) => ({ ...item, delete: false, selected: this.currentSelection(index) })),
      headers: this.fields.map(item => ({
        title: item.label,
        value: item.key,
        type: item.type,
        optionstable: item.optionstable,
        align: 'left',
        width: item.width,
        disabled: item.disabled,
        required: this.isRequired(item)
      })).filter((el) => el.label !== null),
      parentField: 'parent_id',
      addRecordText: 'add a ' + this.tableName + ' record',
      handler: {
        delete_record: this.deleteRow,
        copy_record: this.copyRecord,
        record_selected: this.recordSelected,
        closed: this.submit,
        date_change: this.submit,
      },
      btns: [
        { title: 'Delete', icon: 'mdi-delete', signal: 'delete_record' }
      ],
    }
  },
  created () {
    this.$watch(
      'value', () => {
           this.tableItems = this.value.map((item, index) => ({ ...item, delete: false, selected: this.currentSelection(index) }))
           console.log(this.page)
          },
      { deep: true }
    )
    // this.$watch(
    //   'hotGlobal', () => {
    //        console.log(this.hotGlobal)
    //       },
    //   { deep: true }
    // )
  },
 computed: {
  lastPage(){return Math.ceil(this.tableItems.length / this.itemsPerPage)},

 },
  methods: {
   
    inMarkObject(record){
      let filtered = []
      try{
        let key = `${record.field_species}_${record.age}_${record.size_class}`
        filtered = Object.keys(this.formatter).filter((el)=>
        el === key);
      }catch{
        console.log("field_species not found")
      }
      return filtered.length > 0;
    },
    submit (item) {
      this.$emit('submit', { payload: [item], table: this.tableName })
    },
    
    isFirstRecord (index) {
      return index === 0
    },
    currentSelection (index) {
      return this.$store.getters['data/tableData'](this.tableName)[index] === this.$store.getters['data/getCurrentRecord'](this.tableName)
    },
    selectValue (value) {
      if (value !== undefined) {
        return value.toString()
      }
      return ''
    },

    isSelected (item, selectedItem) {
      return item[this.keyField] === selectedItem[this.keyField]
    },
    deleteRow (record) {
      const deleteInfo = { table: this.tableName, records: [record] }
      this.$emit('delete_record', deleteInfo)
    },
    copyRecord (record) {
      // const info = { table: this.tableName, record: record }
      this.$emit('copy_record', record)
    },
    recordSelected (record) {
      if (record.selected) {
        this.tableItems = this.value.map((item) => ({ ...item, delete: false, selected: this.isSelected(item, record) }))
        this.$emit('record_selected', record)
      }
    },
    addRow () {
      this.$emit('request_new_record', this.tableName)
    },
    addHotRecord(species, age){
      this.$emit('request_hot_record',{tableName:this.tableName,species:species,age:age})
    },
    isRequired (item) {
      if ('required' in item) {
        return item.required
      } else {
        return false
      }
    },
    handleClick(i) {
      console.log(i)
    },
    updateItem(info) {
      if (this.tableName === "catch"){
        info.record.size_class = this.findSizeClass(info.record.length)
      }
      support.saveRecord(this.tableName, info.record)
    },

    findSizeClass(l) {
      let p = this.refTables['size_class'].filter((el) => 
      el.min_length_mm <= l && el.max_length_mm >= l) 
      return p.length > 0 ? p[0]['code'] : 'n/data'
    }
   
  }
}
</script>
<style>
.btn {
  margin-top:10px;
}
</style>