import { createRouter } from 'vue-router'
import { createWebHashHistory } from 'vue-router'
import Summary from '@/views/Summary.vue'
import Login from "@/components/Login.vue";
import DataCollect from "@/views/DataCollect.vue";
import Home from "@/views/Home.vue";

const routes = [
  //{ path: '/', component: Home, name: "home"},
  // { path: '/about', component: About },
  
  // {
  //   path: "/help",
  //   name: "help",
  //   component: HelpView,
  // },
  {
    path: "/login/",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "collect",
    component: DataCollect,
  },
  {
    path: "/scan/",
    name: "scan",
    component: Home,
  },
   {
    path: "/summary",
    name: "Summary",
    component: Summary,
   },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We
  // are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})
export default router
