<template>
<v-data-table
    ref="datatable"
    :items="tableItems"
    :item-key="id"
></v-data-table>
 </template>

<script>
import support from '../plugins/support'


export default {
    props: {
    },
    data() {
        return {}

    },
    mounted() {
        support.refreshViews();
    }, 
    computed: {
        tableItems() {
            return this.$store.getters['data/viewData']('top_level')
        }
    }
}
</script>