import { createStore } from 'vuex'
import condition from './modules/condition'
import data from './modules/data'



// Create a new store instance.
//const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    condition,
    data
  },
  strict: false,
  
})