<template>
  <ShowData></ShowData>
</template>

<script>

import ShowData from "../components/showData.vue";
export default {
  name: "Summary-Form",

  components: { ShowData },
};
</script>
