<template>
<v-app> 
  <v-container class="app"
  app color="primary" dark> 

  </v-container>
  </v-app>
</template>

<script>

// @ is an alias to /src


export default {
  name: 'HomePage',
  components: {  },
  props:{
   
  },
  methods:{
    
  },
  data() {
    return {
         }
  },
  async mounted(){
      },
  computed:{

   }
}
</script>