<template>
    <v-container class="app"
    app color="primary" dark>
    <v-row>
      <v-select
      label="Tributary"
      v-model="tributary"
      :items="refTables['tributary']"
          item-text="title"
          item-value="code"
          :disabled="!canEditKeys"
               >
    </v-select>
    <v-select
      label="Site"
      v-model="station"
      :items="filteredSiteList"
          item-text="title"
          item-value="code"
          :disabled="!canEditKeys"
               >
   </v-select>
  
   <Datepicker v-model="visit_date" 
        model-type="yyyy-MM-dd"
        required
        clearable
        :disabled="!canEditKeys"
        />
      </v-row>
      <v-row>
    <v-text-field
    label="Temperature (c)"
    v-model="currentRecord['temperature_c']"
    clearable
    type="number"
    :disabled="!recordSet"
    @input="saveRecord"
    > 
    </v-text-field>  
    <v-text-field
    label="Investigators"
    v-model="currentRecord['investigators']"
    clearable
    :disabled="!recordSet"
    @input="saveRecord"
    > 
    </v-text-field>  
    <v-select
    label="Gear"
    v-model="currentRecord['gear']"
    :items="refTables['gear']"
          item-text="title"
          item-value="code"
    clearable
    :disabled="!recordSet"
    @input="saveRecord"
    > 
    </v-select>  
  </v-row>
  <v-text-field
    label="Comment"
    v-model="currentRecord['comments']"
    clearable
    :disabled="!recordSet"
    @input="saveRecord"
     > 
    </v-text-field>  
  </v-container>
</template>
<script>
//net set has radio button to handle selecting a set.
// net set handles catch and tally permissions
//Site 
import support from '@/plugins/support.js';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
export default {
  name: 'SiteVisitEntry',
  
  components: { Datepicker },
  props:{
    selectedRecord: Object,
    refTables: Object
  },
  data() {
    return {
      tableName: "site_visit",
      keys:['station','tributary','visit_date'],
      editClicked: false,
      sites:[],
      currentRecord: this.selectedRecord
    }
  },
  created() {
    this.$watch(
      "recordSet",
      () => {
          this.$emit('site_visit_set') //tell the data collection view to change the record
      },
      { deep: true }
    );
    this.$watch(
      "selectedRecord",
      () => {
        this.$store.commit('data/updateAttribute',{attribute:'current_site_visit',value:this.selectedRecord})
        this.currentRecord = this.$store.getters['data/getCurrentRecord']('site_visit')
      },
      { deep: true }
    );

  },
  async mounted(){


   },
  computed:{
    //Filter the site list to only those within a selected tributary
    filteredSiteList() {
      return this.refTables['site'].filter((el)=>
        (el.tributary === this.tributary)) //The original site table did not include tributary so we need to handle undefined tributary field
    },
    station: {
      get() {return this.$store.getters['data/attribute']('selectedStation')},
      set(value) {
        this.$store.commit('data/updateAttribute',{attribute:'selectedStation', value:value})
      }
    },
   tributary: {
    get() {return this.$store.getters['data/attribute']('selectedTributary')},
      set(value) {
        this.$store.commit('data/updateAttribute',{attribute:'selectedTributary', value:value})
      }
    },
    visit_date: {
      get() {return this.$store.getters['data/attribute']('selectedVisitDate')},
      set(value) {
        this.$store.commit('data/updateAttribute',{attribute:'selectedVisitDate', value:value})
      }
    },

    recordSet() {
      return (this.station !== undefined && this.station !== '') &&
          (this.tributary !== undefined && this.tributary !== '') &&
           (this.visit_date !== undefined && this.visit_date !== '')
           
    },
    canEditKeys() {
      return (!this.recordSet) | (this.recordSet & this.editClicked) ? true : false
    },
  
  },
  methods: {

    saveRecord() {
      console.log("save button pressed")
      if ((this.station !== undefined & this.station !== '') &
           (this.tributary !== undefined & this.tributary !== '') &
           (this.visit_date !== undefined & this.visit_date !== '')) {
            this.currentRecord.station = this.station
            this.currentRecord.tributary = this.tributary
            this.currentRecord.visit_date = this.visit_date
          support.saveRecord( //writes to local forage, on return we update the store
                  this.tableName, this.currentRecord)
            .then((result) => {
              this.$store.commit('data/updateAttribute',{attribute: `current_${this.tableName}`, value:result}) 
  
            })

          }
          
    },
   
  },
  
}
</script>