import support from '@/plugins/support'
// initial state
const state = () => ({
    // data from device_manager.digital_resources
    appName: 'Catch Data',
    unsavedData: false,
    siteVisitRecord:{},
    netSetRecord:{},
    selectedStation:'',
    selectedTributary:'',
    selectedVisitDate:'',
    selectedSetNum: 0,
    lastSpecies:'CHUM',
    tableFields: {
      site_visit: [{key:'temperature_c', label: null, type:'number'}],
      net_set:[ {key:'set_num', label: null, type:'number'}],
      tally: [
        {key:'_id', label: null, type:null},
        {key:'species', label:'Species', type:'select', optionstable:"species"},
        {key:'age', label:'Age',type:'select', optionstable:'age'},
        {key:'size_class', label: 'Size Class', type:'select', optionstable:'size_class'},
        {key:'tally', label: 'Count', type:'number'},
        {key:'comment_field',label: 'comment', type:'text'},
        {key:'global_id',label: null, type:null, keyfield: true},
        {key:'parent_id',label: null, type:null},
        {key:'set_num', label: null, type:'number'}
    ],
    catch: [
      {key:'_id', label: null,type:null},
      {key:'field_species', label:'Species', type:'select', optionstable:"species"}, 
      {key:'age', label:'Age',type:'select', optionstable:'age'},
      {key:'length', label: 'Length', type:'number'},
      {key:'mass_g',label: 'Mass(g)', type:'number'},
      {key:'sin', label:'SIN',type:'barcode'},
      {key:'comment_field',label: 'comment', type:'text'},
      {key:'sample_type',label: 'Sample', type:'select', optionstable: 'sample_type'},
      {key:'size_class', label: 'Size Class', type: 'select', optionstable:'size_class'},
      {key:'global_id',label: null, type:null, keyfield: true},
      {key:'parent_id',label: null, type:null},
      {key:'set_num', label: null, type:'number'}
  ]},
  dataCache:{site_visit:183,
  net_set:183,
  catch:7,
  tally:7},
    tableKeys: {site_visit:['station','tributary','visit_date'],
    net_set:['station','tributary','visit_date', 'set_num'],
    catch:['station','tributary','visit_date', 'set_num','global_id'],
    tally:['station','tributary','visit_date', 'set_num','global_id'],
    },
  dataTables: {site_visit:[],
  net_set:[],
catch:[],
tally:[]},
    tablePositions: {site_visit:-1,
    net_set:-1,
    catch:-1,
    tally:-1},
    views:{catch:[]},
    refTables:{site:[], tributary:[],
  species:[],
  size_class:[],
  sample_type:[],
  age:[],
  hot_buttons:[]
},
})

  
  // getters
  const getters = {  
    tableFields: (state) => (tableName) => state.tableFields[tableName],
    dataCache: (state) => (tableName) => state.dataCache[tableName],
    tableData: (state) => (tableName) => state.dataTables[tableName],
    refTable: (state) => (tableName) => state.refTables[tableName],
    viewData: (state) => (tableName) => state.views[tableName],
    getCurrentRecord: (state) => (tableName) => state[`current_${tableName}`] ? state[`current_${tableName}`] : support.newRecord(tableName),
    refTables: (state) => state.refTables,
    dataTables: (state) => state.dataTables,
    appName: (state) => state.appName,
    tableRecord: (state) => (info) => state.dataTables[info.tableName][info.position],
    attribute: (state) => (varname) => state[varname]
    }
  
  // actions
  const actions = {

     }
  
  // mutations
  const mutations = {
    updateAttribute(state, payload){
      state[payload.attribute] = payload.value
      if (payload.attribute === 'current_site_visit') {
        this.commit("data/setSiteVisitRecord")
        }
      // else if (payload.attribute === 'selectedSetNum') {
      //   this.commit('data/setNetSet')
      // }
    },

    setNetSet(state) {
      state.dataTables.catch = []
      state.dataTables.tally = []
      //load the sets
      support.getForageRecords('net_set').then((result) => {
        let records = result.filter((el) =>
          el.station === state.current_site_visit.station &
        el.visit_date === state.current_site_visit.visit_date
        )
        state.dataTables.net_set = records
      

      state.current_net_set = state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum)).length > 0 ? state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum))[0] : support.newRecord('net_set')
      })
 //catch and tally records
        state.current_catch = support.newRecord('catch')
      
        support.getForageRecords('catch').then((result) => {
          let records = result.filter((el) =>
          el.parent_id === state.current_net_set.global_id)
          state.dataTables.catch = records
        })

        state.current_tally = support.newRecord('tally')
        support.getForageRecords('tally').then((result) =>{
          let records = result.filter((el) =>
          el.parent_id === state.current_net_set.global_id)
          state.dataTables.tally = records
        })
      
      },
    
    // updateRecord(state, payload) {
    //   if (payload.position !== undefined) { 
    //     state.dataTables[payload.tableName][payload.position] == payload.record
    //   }
    // },
    setSiteVisitRecord(state){
      support.getForageRecords('net_set').then((result) => {
        let records = result.filter((el) => 
        el.station === state.current_site_visit.station &
        el.visit_date === state.current_site_visit.visit_date
        )
        state.dataTables.net_set = records
        state.dataTables.catch = []
        state.dataTables.tally = []
        state.netSetRecord = state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum)).length > 0 ? state.dataTables.net_set.filter((el) =>
        el.set_num === parseInt(state.selectedSetNum))[0] : support.newRecord('net_set')
        records.map((record) => { //for each net_set we filter matching catch and tally
          support.getForageRecords('catch').then((result) => {
            let records = result.filter((el) =>
          el.parent_id === record.global_id)
            state.dataTables.catch = state.dataTables.catch.concat(records)
          }
          )
          support.getForageRecords('tally').then((result) =>{
            let records = result.filter((el) =>
          el.parent_id === record.global_id)
            state.dataTables.tally = state.dataTables.tally.concat(records)
          }
        )})
    })

    },

    updateChildren(state, payload ) {
      state.dataTables[payload.childTable].map((item) => {
        if(item.parent_id === payload.parentID) {
          item[payload.childField] = payload.newValue
          support.writeRecordToLocalForage({tableName:payload.childTable, record:JSON.parse(JSON.stringify(item))})
        }
      })
    },
    
    refreshRecords(state, payload) {
      if (payload.tableType != null) {
        state[payload.tableType][payload.tableName] = payload.records;
      } else {
        state[payload.tableName] = payload.records;

      }
      support.syncLocalForageFromStore(payload)
    },
   
    resetPrimary(state) {
      state.siteVisitRecord = {}
      state.selectedStation=''
      state.selectedTributary=''
      state.selectedVisitDate=''
      state.selectedSetNum = 0
    },
  }
    
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    }