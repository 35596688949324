<template>
  <v-container>
    <v-card justify="center">

      <v-card-text>
        <v-row>
          <v-btn @click="close">Close Camera</v-btn>
        </v-row>
        <v-row>
        {{ msg }}
      </v-row>
        <v-row>
        <StreamBarcodeReader
         :track="paintBoundingBox"
        :formats="barcodeFormats"
        @error="onError"
        @decode="onDecode"
        @loaded="onLoaded"
      /></v-row>
        <!-- <StreamBarcodeReader @decode="onDecode" @error="onError"></StreamBarcodeReader> -->
         </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import  StreamBarcodeReader from "vue-barcode-reader";
//import { StreamBarcodeReader } from '@teckel/vue-barcode-reader'


export default {
  name: "ScanCode",
  components: { StreamBarcodeReader },
  data: () => ({
    msg:'Loading',
   
  }),
  props: { 
    field: String
  },
  created() {
    
  },
  async mounted() {},

  computed: {},
  methods: {
    onDecode (result) { 
      console.log(result) 
      this.$emit('barcodeInput', {value:result, field:this.field})
      this.msg = result
      this.close()
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height }
        } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },
    onError(err) {
      var error = ''
        error = `[${err.name}]: `

        if (err.name === 'NotAllowedError') {
          error += 'you need to grant camera access permission'
        } else if (err.name === 'NotFoundError') {
          error += 'no camera on this device'
        } else if (err.name === 'NotSupportedError') {
          error += 'secure context required (HTTPS, localhost)'
        } else if (err.name === 'NotReadableError') {
          error += 'is the camera already in use?'
        } else if (err.name === 'OverconstrainedError') {
          error += 'installed cameras are not suitable'
        } else if (err.name === 'StreamApiNotSupportedError') {
          error += 'Stream API is not supported in this browser'
        } else if (err.name === 'InsecureContextError') {
          error += 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
        } else {
          error += err.message
        }
        return error
  },
 
  close () {
    this.$emit('closeScanner')
  },
  onLoaded(){
    this.msg = "ready to scan"
  }
}
};
</script>

<style></style>
