<template>
  
    <v-container class="app"
    app color="primary" dark>
    <v-fab 
          location="bottom end"
          size="small"
          absolute
          position="fixed"
          scroll-strategy="reposition"
          color="success"
        >
        <span v-if="countDown > 0">
            {{ Math.round(countDown/60,0) }}
          </span>
       </v-fab>
      <v-row>
      <v-col cols="12" md="6">
        <v-row>
        <v-btn
        @click="startNow">Start</v-btn>
      <Datepicker 
        v-model="stime"
        format="yyyy-MM-dd hh:mm"
        :enable-time-picker="true"
        required
        clearable
        @input="saveRecord"
        />
      </v-row>
        <v-row>
        <v-text-field
        v-model=record.start_latitude
        label="Start Latitutde"
        clearable
        required
        @input="saveRecord"
        ></v-text-field>
        <v-text-field
        v-model=record.start_longitude
        label="Start Longitude"
        clearable
        required
        @input="saveRecord"
        ></v-text-field>
        </v-row>
        <v-row>
         <v-text-field
        v-model.number=record.start_depth
        label="Depth"
        clearable
        type="number"
        required
        @input="saveRecord"
        ></v-text-field>
        <v-text-field
        v-model.number=record.start_flow
        label="Flow rate"
        type="number"
        clearable
        required
        @input="saveRecord"
        ></v-text-field>
      </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
         <v-btn
        @click="EndNow">End</v-btn> 
        <Datepicker 
        v-model="etime"
        format="yyyy-MM-dd hh:mm"
        :enable-time-picker="true"
        required
        clearable
        @input="saveRecord"
        />
      </v-row>
        <v-row>
        <v-text-field
        v-model=record.stop_latitude
        label="Latitude"
        clearable
        required
        @input="saveRecord"
        ></v-text-field>
        <v-text-field
        v-model=record.stop_longitude
        label="Longitude"
        clearable
        required
        @input="saveRecord"
        ></v-text-field>
        </v-row>
        <v-row>
         <v-text-field
        v-model=record.stop_depth
        label="Depth"
        clearable
        type="number"
        required
        @input="saveRecord"
        ></v-text-field>
        <v-text-field
        v-model=record.stop_flow
        label="Flow rate"
        type="number"
        clearable
        required
        @input="saveRecord"
        ></v-text-field>
        </v-row>
        </v-col>
        <v-row>
          <v-text-field
        v-model=record.comment
        label="Comment"
        type="text"
        :class='bg-red'
        clearable
        required
        @input="saveRecord"
        ></v-text-field>
        </v-row>
        </v-row>

      
  </v-container>
</template>
<script>
//net set has radio button to handle selecting a set.
// net set handles catch and tally permissions
//Site 
import support from '@/plugins/support.js';
import Datepicker from '@vuepic/vue-datepicker';


import '@vuepic/vue-datepicker/dist/main.css';
export default {
  name: 'NetSet',
  
  components: { Datepicker },
  props:{
    selected_record: Object
  },
  data() {
    return {
      countDown: -1,
      CountDownTime: 60*15, //15 minute
      stime: '',
      etime:'',
      audio: new Audio("/assets/bell.mp3"),
      tableName: "net_set",
      record: this.selected_record,
      overlay: true
          
    }
  },
  created() {
    
  },
  async mounted(){
    support.turnGPSOn();
    this.stime= this.record.start_time === '' ? '' : Date.parse(this.record.start_time);
    this.etime= this.record.stop_time === '' ? '' : Date.parse(this.record.stop_time);
       this.$watch(
      "selected_record",
      () => {
        this.record = this.selected_record;
      },
      { deep: true }
    );
 this.$watch(
      "stime",
      () => {
        if (this.stime != ''){
        this.record.start_time = support.getLocalTimestamp(this.stime);
        } else {
          this.record.start_time = ''}
        this.saveRecord()
      },
      { deep: true }
    );
     this.$watch(
      "record.start_time",
      () => {
        this.stime= this.record.start_time === '' ? '' : Date.parse(this.record.start_time);
      },
      { deep: true }
    );
    this.$watch(
    "etime",
      () => {
        if (this.etime != ''){
          this.record.stop_time = support.getLocalTimestamp(this.etime);
        } else {this.record.stop_time = ''}
        this.saveRecord()
      },
      { deep: true }
    );
     this.$watch(
      "record.stop_time",
      () => {
        this.etime= this.record.stop_time === '' ? '' : Date.parse(this.record.stop_time);
      },
      { deep: true }
    );
    this.$watch(
      "selected_record",
      () => {
        this.record = this.selected_record;
      },
      { deep: true }
    );
  },
  computed:{
   
    refTables() {
      return this.$store.getters['data/refTables']
    },
   
    records() {
      return this.$store.getters['data/tableData'](this.tableName)
    },
  },
  methods: {
       countDownTimer (global_id) {
          if (this.countDown > 0) {
              setTimeout(() => {
                  this.countDown -= 1
                  this.countDownTimer(global_id)
              }, 1000)
          } else {
            console.log(global_id)
            this.setEnd(global_id)
            this.audio.play()
            this.audio.play()
            this.audio.play()
          }
            },
    // onStartChange() {
    //     this.$emit("date-change", this.stime)
    // },
    async setEnd (global_id){ //time delay so we find the record by global id
    let et = support.getLocalTimestamp(new Date())
      await support.updateRecord('net_set',global_id, [{field:'stop_time',value:et},
      {field:'stop_latitude',value:support.getLatitude()},
      {field:'stop_longitude',value:support.getLongitude()}]).then() //this writes it to local forage

      let p = support.findPosition(this.records, "global_id", global_id)
      if (p >=0){
        
        let recordFound = this.records[p]
        recordFound.stop_time = et
        recordFound.stop_latitude = support.getLatitude()
        recordFound.stop_longitude = support.getLongitude()
        support.saveRecord('net_set',recordFound)
        .then(this.$store.state.commit('data/setNetSet')) //need to call setNetSet to update the set list for sets that are updated in the background
      }

    },
    setStart () {
     
      this.record.start_time = support.getLocalTimestamp(new Date())
      this.record.start_latitude = support.getLatitude()
      this.record.start_longitude = support.getLongitude()
      this.saveRecord()
    },
    startNow() {
      this.setStart()
      this.countDown = this.CountDownTime
      this.countDownTimer(this.record.global_id)
    },
    EndNow() {
      this.record.stop_latitude = support.getLatitude()
      this.record.stop_longitude = support.getLongitude()
      let et = support.getLocalTimestamp(new Date())
      this.record.stop_time = et;
      this.countDown = -1
    },
    saveRecord() {
      this.record.station = this.record.station === undefined | this.record.station === '' ? this.$store.getters['data/getCurrentRecord']('site_visit').station :
      this.record.station
      this.record.visit_date = this.record.visit_date === undefined | this.record.visit_date === '' ? this.$store.getters['data/getCurrentRecord']('site_visit').visit_date :
      this.record.visit_date
      support.saveRecord(
              this.tableName, this.record)
        .then((result) => {
          this.$store.commit('data/updateAttribute',{attribute: `current_${this.tableName}`, value:result}) 
          })
          
    },
    // updateChildren(){
    //   if (support.allKeysPopulated(this.tableName,this.record)) {
    //     this.recordSet = true  
    //   }
    // },
    // saveRecord() {
    //   this.record.insert_ts = this.record.insert_ts !== '' & this.record.insert_ts !== undefined ? 
    //     this.record.insert_ts :
    //     support.getLocalTimestamp(new Date());
    //   this.record.edit_ts = support.getLocalTimestamp(new Date())
    //   this.$store.commit('data/saveRecord',{tableName: this.tableName,record:this.record})
    // }
  },
  
}
</script>