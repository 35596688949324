<template>
  <v-app>
    <v-container>
      <v-form class="login" @submit.prevent="login">
        <h1>Sign in</h1>
        <v-row>
          <v-col cols="8">
            <v-text-field
              class="mt-2"
              id="name"
              required
              v-model="username"
              label="Username"
              autocapitalize="off"
              dense
            />
          </v-col>
          <v-col cols="4" />
        </v-row>
        <v-row cols="8">
          <v-col>
            <v-text-field
              id="password"
              required
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Password"
              @click:append="show = !show"
              autocapitalize="off"
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-btn @click="login">Login</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "LogIn",
  data() {
    return {
      show: false,
      
      password: "",
    };
  },
  computed:{
    username: {
      get() {
        return this.$store.getters['condition/username']
      },
      set(value) {
        this.$store.commit("setUserName", value);
      }
    }
  },
  methods: {
    login() {
      let username = this.username;
      let password = this.password;
      let dbName = this.$store.getters['condition/dbName'];
      this.$store
        .dispatch("condition/login", {
          dbName: dbName,
          username: username,
          password: password,
        })
        .then(() => this.$router.push(this.$route.query.redirect || "/")) // Go Home if login was successful
        .catch((err) => console.log(err));
    },
  },
};
</script>
