import { createApp } from 'vue'
import "./registerServiceWorker";
import App from './App.vue'
// Cookies
import Cookies from "vue-cookies";
import StreamBarcodeReader from 'vue-barcode-reader'

// Datetime
//import { Datetime } from "vue-datetime";
//import "vue-datetime/dist/vue-datetime.css";
import "vuetify/dist/vuetify.css";
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//router
import router from './router'
//store
import store from './store'

const vuetify = createVuetify({
  components,
  directives,
  })
createApp(App).
use(vuetify).
use(router).
use(store).
use(Cookies).
use(StreamBarcodeReader).
//use(Datetime).
mount('#app')
